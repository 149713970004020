.contact-icon {
    height: 80px;
    width: 80px;
}

.form {
    background: #fff;
    box-shadow: 2px 4px 16px rgba(9, 30, 86, 0.2);
    border-radius: 15px;
    padding: 32px 20px;
    text-align: center;
    margin: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-element {
  border: 1.55px solid #12408B;
  border-radius: 8px;
}


.form-border {
    border-radius: 10px;
    padding: 20px; 
}


.map-location {
    min-height: 500px;
}

.contactus-icon {
  font-size: 45px;
}