@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300&display=swap');
@import './constants/ServiceConstants'

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.techganah-title {
  font-size: 50px;
  font-weight: 600;
}



  
  /* Font size for laptop screens and larger (min-width: 992px) */
  @media (min-width: 992px) {
    .techganah-title {
      font-size: 80px;
      font-weight: 600;
    }

    .section-gif {
    transform: matrix(1, 0, 0, 1, -55.7, -18.6);
    padding-left: 60px;
    height: 700px;
    width: 100%;

    }
  }

.tag-line {
    font-size: 20px;
}


.home-our-services {
    background-color: #f0f2c9;
}

.title-services {
    font-size: 80px;
    font-weight: bold;
    /*color:darkgoldenrod;*/
}

.service-image {
    width: 200px;
}
.card-service{
    background-color:#f4f5db;
    border-radius: 20px ;
    box-shadow: #ffcf2d;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.card-service:hover {
    /* Brighten the card on hover */
    background-color: rgba(255, 255, 255, 0.9); 
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); 
  }

.home-our-clients {
    background-color:aliceblue;
}
.card-client {
    width: 60%;
    border: none;
    box-shadow: #ffcf2d;
}

.feedback-image {
    border-radius: 50%;
    width: 130px;
    height: 130px;
}
.card-testimonial {
    font-size: 20px;
    height: 450px;
    width: auto; 
    background-color: #f4f4f4;
    border-radius: 30px;
}


@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.clients {
  overflow: hidden;
  padding: 60px 0;
  white-space: nowrap;
  position: relative;
}



.clients:hover .clients-slider {
  animation-play-state: paused;
}

.clients-slider {
  display: inline-block;
  animation: 35s slide infinite linear;
}

.clients-slider img {
  height: 100px;
  margin: 0 40px;
}