.about-us-header {
  background-color: #E8E8E8;
}


.our-story-image {
  width: 100%;
  height: 400px;

}

.our-story-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-content-left {
  text-align: left; 
}

.centered-content-right {
  text-align: right; 
}

.what-makes-us-best-card {
  background: #fff;
  box-shadow: 0 4px 16px rgba(32,76,188,.2);
  border-radius: 8px;
  padding: 32px 20px;
  text-align: center;
  align-items: center;
}