@import './constants/ServiceConstants'

html {
  scroll-behavior: smooth;
}

.global-container{
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  max-width: 1450px;
  text-align: center;
}

.section-header-title {
    font-size: 35px;
    font-weight: 600;
  }

.global-title {
  font-size: 50px;
  font-weight: 600;
}


/*heading title for desktop*/
@media (min-width: 992px) {
  .section-header-title {
    font-size: 55px;
  }

}


.icon {
  font-size: 45px;
}

.grey-background{
  background-color: #f9f9f9;
}

.tg-blue-background {
  background-color: #12408B;
}

.tg-blue-colour {
  color: #12408B;
}

.tg-yellow-colour {
  color: #ffcc00;
}

.btn-primary{
  background-color: #12408B;
  color: white;
}