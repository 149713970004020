.services-comp-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    max-width: 1800px;
    background-color: #ffffff;
}


.service-card {
    width: 100%; 
    height: 300px; 
    background-size: cover !important; 
    background-position: center !important;
    position: relative;
    transition: all .3s;
    background: #fff;
    box-shadow: 0 4px 16px rgba(32,76,188,.2);
  }
  
  
  .service-card h4 {
    color: #fff;
  }
  
  .service-card p {
    color: #fff; 
  }
  

  .course-intro {
    background: #e9e6e6;
    border-radius: 4px;
    box-shadow: 0 2px 29px 0 rgba(15,46,64,.12);
    margin: -70px auto 30px;
    padding: 30px 25px;
    position: relative;
    width: 95%;
    overflow: hidden;
    border-bottom: 4px solid #fff;
    text-align: center;
  }

.services-tile:hover {
    background-color: #12408B;
    
}

.service-card:hover {
    filter: brightness(70%);
}

.course-intro:hover + .services-tile .service-card {
    filter: brightness(70%);
}

.enroll {
  height: 50px;
  width: 285px;
  font-size: 20px;
}

