@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

body {
    font-family: 'Rubik', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

.logo-image {
    margin-left: 10px;
    height: 70px;
    width: 140px; 
  }
 
@media (min-width: 992px) {
  .logo-image {
    margin-left: 50px;
    height: 50px;
    width: 100px; 
  }
}
  
.navbar-nav {
    font-size: 22px; 
    line-height: 2; 
    font-weight: 600;
    background-color: #f9f9f9;;
/*    color: black;*/
}

.navbar-item {
    color: #686565;
}

.offcanvas-head {
    background-color: white;
}

.offcanvas-body {
    background-color: #f9f9f9;;
}

.nav-active {
    text-decoration: underline;
}