.carousel-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1700px;
  position: relative;
  background-color: #ffffff;
}



.carousel-cards-list {
  width: 100%;
  padding: 10px;
  align-items: center;
}

.carousel-card{
  background: #fff;
  box-shadow: 0 4px 16px rgba(32,76,188,.2);
  border-radius: 8px;
  padding: 32px 20px;
  text-align: center;
  margin: 12px;
  align-items: center;
  
}

.carousel-card-testimonial{
  background: #fff;
  box-shadow: 0 4px 16px rgba(32,76,188,.2);
  border-radius: 8px;
  padding: 32px 20px;
  text-align: center;
  margin: 12px;
  align-items: center;
  
  
}

.carousel-card-img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 90%;
}

.carousel-card-p-text {
  font-size: 18px;
  font-weight: 200;
}

.carousel-card-person-name {
  font-size: 22px;
  font-weight: 900;
  color: #365e7c;
}


.instructor-name {
  font-size: 27px;
  line-height: 27px;
  font-weight: bold;
  margin-bottom: 0;
  color: #365e7c;
}

.testimonial-name {
  font-size: 27px;
  line-height: 27px;
  font-weight: bold;
  margin-bottom: 0;
  color: #365e7c;
}

.carousel-testimonial-header{
  text-align: center;
  justify-content: center;
}


.instructor-company {
  max-width: 193px;
  height: 73px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 22px;
}

.instructor-company-img {
  width: 150px;
  height: 80px;
  max-width: 150px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: left;
  object-fit: fill;
}


.instructor-linkedin{
  width: 55px;
  height: 55px;
}


.rating-desc {
  font-size: 20px;
  font-weight: 200;
}

.quotes {
  font-size: 25px;
}