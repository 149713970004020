.faq-question {
    color: #fadf07;
}
  
.service-faqs {
     /* background-color: #88cdd9; */
     background-color: #f4f4f4;
     padding-bottom: 60px;
     padding-top: 60px;
}
  
.courses-card {
  border: 0;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 16px rgba(3, 12, 34, 0.2);
  background-color: #f4f4f4;
}


.course-button {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 25px;
  
}


.batch-image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: 100%;
  /* height: 400px; */
}

.batch-data {
  font-size: 20px;
}

.course-register {
  width: 100%;  
  background-color: #385964;
}

.batch-details {
  /* background-color: #F9F9F9; */
  background-color: #385964;
  border-radius: 10px;
}

.batch-card {
  /* background-color: #01afb5; */
  color: #385964;
}

.stats{
  background-color: #393c3f;
}
.stats-container{
  margin-left: auto;
  margin-right: auto;
}


.stats-card {
  padding-left: 30px;
  padding-right: 30px;
  background-color: #393c3f;
  border: none;
  color: #ccf3e5;
}


.stat-image{
  width: 80px;
  height: 80px;
}

.stat-value{
  font-size: 80px;
  font-weight: 600;
}

.stat-name {
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
}

/*temp solution for mobile view stats banner*/
@media (max-width: 767px) {

  .stats-card {
  background-color: #393c3f;
  border: none;
  color: #ccf3e5;
}

  .stat-image{
    width: 40px;
    height: 40px;
  }

  .stat-value{
    font-size: 40px;
    font-weight: 300;
  }

  .stat-name {
    font-size: 15px;
    font-weight: 200;
    text-transform: uppercase;
  }

}


.coursemodules {
  background-color: #e4eaed;
}





.selected-card{
  background-color: #d0e2fe;
  color: #054d32;
}

.selected-card.img {
  filter: brightness(200%);
}


.batch-details-card {
  border-color: #9868ef!important;
  border-radius: 25px;
  background-color: #fff;
  box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
}

.batch-details-image{
  width: 250px;
  height: 200px;
}


.batch-detail-header{
  font-size: 35px;
  font-weight: 700!important;
}

.batch-date-card {
  background-color: #F3F5FD;
  border-radius: 10px;
  max-width: 250px;
  margin: 3px;
}

.custom-dropdown {
    width: 400px; 
}



/* web-and-mobile-app-service*/
.app-card-image {
  width: 300px;
  height: 180px;
  
}

.application-card {
  cursor: pointer;
  transition: transform 0.2s ease;
  width: 100%;
  height: 250px;
  border-color: #12408B ;
  color: blue;
  text-transform: uppercase;
  text-decoration: underline;
}

.application-card.enlarged {
  transform: scale(1, 1.5);
  background-color: #12408B;
  color: #ccf3e5;
  text-decoration: none;
}

.yellow-background {
  z-index: 0;
  position: absolute;
  background-color: #ffcf2d;
  height: 200px;
    bottom: auto;
    left: 0%;
    right: 0%;
    color: #385964;
}

.course-card img {

  width: 100px;
  height: 100px 

}

.course-card-info{
  background-color: #fcdb00;
  font-size: 20px;
  font-weight: 300;
}

.services-offered-card:hover{
  box-shadow: 0 4px 8px 0 rgba(220, 146, 146, 0.2);
}

.services-offered-image{
  width:100px;
  height: 100px;
}

.services-offered-card-heading{
  font-size: 20px;
  font-weight: 600;
}

.services-offered-description{
  width: 200px;
  margin: 0 auto; 
  text-align: center;
}

{/*microservices*/}

.microservice-image{
  
  height: 400px;
}

.microservice-animation {
  overflow: hidden;
  position: relative;
  height: 1.5em; 
 font-size: 30px;
}

.slide-in {
  position: absolute;
  bottom: 0;
  animation: slide-in 2.24s ease infinite;
  color: #2d42ff;
}

@keyframes slide-in {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

